import React from 'react'

const IconCoach = ({color = '#000000', ...rest}) => (
  <svg
    {...rest}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>IconCoach</title>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.11892 3.91002C8.0452 2.50647 10.4094 1.83706 12.7856 2.02243C15.1618 2.20781 17.3936 3.23577 19.0789 4.92107C20.7642 6.60638 21.7922 8.83821 21.9776 11.2144C22.1629 13.5905 21.4935 15.9548 20.09 17.8811C18.6864 19.8073 16.641 21.169 14.3223 21.7206C12.1442 22.2388 9.85942 22.0102 7.8325 21.0788L2.32104 22.9471C1.96058 23.0692 1.56202 22.9762 1.29289 22.7071C1.02376 22.438 0.930743 22.0394 1.05293 21.6789L2.92122 16.1675C1.98979 14.1406 1.76117 11.8558 2.27937 9.67765C2.831 7.35898 4.19265 5.31358 6.11892 3.91002ZM12.6301 4.01637C10.7291 3.86808 8.83773 4.4036 7.29671 5.52645C5.75568 6.64929 4.66637 8.28561 4.22507 10.1405C3.78376 11.9955 4.01948 13.947 4.88976 15.6436C5.01325 15.8843 5.03392 16.1648 4.94706 16.421L3.59737 20.4026L7.57896 19.0529C7.83519 18.9661 8.11569 18.9867 8.35642 19.1102C10.0529 19.9805 12.0045 20.2162 13.8594 19.7749C15.7144 19.3336 17.3507 18.2443 18.4735 16.7033C19.5964 15.1623 20.1319 13.2709 19.9836 11.3699C19.8353 9.46899 19.0129 7.68353 17.6647 6.33529C16.3165 4.98704 14.531 4.16467 12.6301 4.01637Z"
      fill="white"
    />
  </svg>
)

export default IconCoach
