import {combineReducers} from 'redux'
import {combineEpics, ofType} from 'redux-observable'
import {createSelector} from 'reselect'
import {createAction, handleActions} from 'redux-actions'
import {createEnsureAuthenticatedEpic} from '../../utils/epic'
import {of} from 'rxjs'
import {map, mergeMap, catchError} from 'rxjs/operators'

import {FirebaseApi} from '../../api'

export const changeChatMessages = createAction(
  'remente/chat/changeChatMessages',
)
export const createChatMessage = createAction('remente/chat/createChatMessage')
export const createChatMessageFulfilled = createAction(
  'remente/chat/createChatMessageFulfilled',
)
export const createChatMessageRejected = createAction(
  'remente/chat/createChatMessageRejected',
)

/**
 * Reducers
 */

const messagesReducer = handleActions(
  {
    [changeChatMessages]: (state, {payload}) => payload.messages,
  },
  [],
)

export default combineReducers({
  messages: messagesReducer,
})

/**
 * Selectors
 */

const sortedMessages = ({chat}) => {
  let messages = chat.messages
  messages.sort((a, b) => {
    if (a.createTime < b.createTime) return -1
    if (a.createTime > b.createTime) return 1
    return 0
  })
  return messages
}

export const getMessages = createSelector(sortedMessages, sorted => {
  let messages = []
  for (const msg of sorted) {
    if (msg.prompt) {
      messages.push({
        id: `${msg.id}-prompt`,
        sender: 'You',
        content: msg.prompt,
      })
    }
    if (msg.response) {
      messages.push({
        id: `${msg.id}-response`,
        sender: 'Riley',
        content: msg.response,
      })
    }
  }
  return messages
})

export const isTyping = createSelector(sortedMessages, messages => {
  // Look at the last few messages to see if any of them are processing
  // We don't look at all messages just in case the backend bugs out and don't change state properly
  for (const msg of messages.slice(-3)) {
    if (!msg.status) continue
    if (msg.status.state === 'PROCESSING') return true
  }
  return false
})

/**
 * Epics
 */

export const createChatMessageEpic = action$ =>
  action$.pipe(
    ofType(createChatMessage().type),
    map(({payload}) => payload),
    mergeMap(msg => {
      return FirebaseApi.createChatMessage({msg}).pipe(
        map(createChatMessageFulfilled),
        catchError(err => of(createChatMessageRejected(err))),
      )
    }),
  )

const chatMessagesEpic = createEnsureAuthenticatedEpic({
  createObservables: ({uid}) => ({
    messages: FirebaseApi.observeChatMessages(uid),
  }),
  actionFulfilled: changeChatMessages,
})

export const chatEpics = combineEpics(createChatMessageEpic, chatMessagesEpic)
