import React from 'react'
import {withTheme} from 'styled-components'
import {FormattedMessage} from 'react-intl'

import IconAdd from 'material-ui/svg-icons/content/add-circle'
import IconDashboard from 'material-ui/svg-icons/action/account-circle'
import IconResources from 'material-ui/svg-icons/social/school'
import IconPulse from 'material-ui/svg-icons/social/poll'
import IconMore from 'material-ui/svg-icons/navigation/menu'
import CreateMenu from './CreateMenuContainer.js'

import ProfileMenu from './ProfileMenuContainer.js'
import IconTarget from './IconTarget.js'
import IconCoach from './IconCoach.js'

import MainMenuButton from './MainMenuButton.js'

import {Nav, Separator} from './MainMenu.styles.js'

const MainMenuClass = props => {
  const {isElectron, isPulseAvailable, theme} = props
  const {colorMainMenuFg} = theme

  return (
    <Nav isElectron={isElectron}>
      <ul>
        {isElectron && <Separator />}

        <CreateMenu
          renderButton={(Popover, handleClick) => (
            <MainMenuButton
              icon={<IconAdd color={colorMainMenuFg} style={buttonStyle} />}
              link="/create"
              exact={true}
              onClick={handleClick}
            >
              <FormattedMessage id="tabs_create" defaultMessage="Create" />
              {Popover}
            </MainMenuButton>
          )}
        />

        <Separator />

        <MainMenuButton
          icon={<IconDashboard color={colorMainMenuFg} style={buttonStyle} />}
          link="/"
          exact={true}
        >
          <FormattedMessage id="tabs_me" defaultMessage="Me" />
        </MainMenuButton>

        <MainMenuButton
          icon={<IconTarget color={colorMainMenuFg} style={buttonStyle} />}
          link="/achieve"
        >
          <FormattedMessage id="tabs_achieve" defaultMessage="Goals" />
        </MainMenuButton>

        <MainMenuButton
          icon={<IconCoach color={colorMainMenuFg} style={buttonStyle} />}
          link="/coach"
        >
          <FormattedMessage id="tabs_coach" defaultMessage="Coach" />
        </MainMenuButton>

        <MainMenuButton
          icon={<IconResources color={colorMainMenuFg} style={buttonStyle} />}
          link="/resources"
        >
          <FormattedMessage id="tabs_improve" defaultMessage="Resources" />
        </MainMenuButton>

        <Separator />

        {isPulseAvailable && (
          <MainMenuButton
            icon={<IconPulse color={colorMainMenuFg} style={buttonStyle} />}
            link="/pulse"
          >
            <FormattedMessage id="tabs_pulse" defaultMessage="Pulse" />
          </MainMenuButton>
        )}

        {isPulseAvailable && <Separator />}
      </ul>
      <ul>
        <Separator />

        <ProfileMenu
          renderButton={(Popover, handleClick) => (
            <MainMenuButton
              icon={<IconMore color={colorMainMenuFg} style={buttonStyle} />}
              link="/settings"
              exact={true}
              onClick={handleClick}
            >
              <FormattedMessage id="tabs_menu" defaultMessage="Menu" />
              {Popover}
            </MainMenuButton>
          )}
        />
      </ul>
    </Nav>
  )
}

const buttonStyle = {
  width: 30,
  height: 30,
}

const MainMenu = withTheme(MainMenuClass)

export default MainMenu
