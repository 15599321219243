import React, {Component} from 'react'
import {Route, Redirect} from 'react-router-dom'

import MainMenu from './MainMenuContainer.js'
import LoadableRouteHome from './RouteHome.loadable.js'
import LoadableRouteGoals from './RouteGoals.loadable.js'
import LoadableRouteCoach from './RouteCoach.loadable.js'
import RouteResource from './RouteResource.js'
import LodableRouteProfile from './RouteProfile.loadable.js'
import LoadableRouteSettings from './RouteSettings.loadable.js'
import LoadablePulseDashboard from './pulse/Dashboard.loadable.js'
import LoadablePulseDashboardDemo from './pulse/DashboardDemo.loadable.js'

import {Wrap, Body, Main} from './RouteAuthenticated.styles.js'

class RouteAuthenticated extends Component {
  render() {
    const {isAuthenticated} = this.props

    if (!isAuthenticated) return <Redirect push to={{pathname: '/register'}} />

    return (
      <Wrap>
        <Body>
          <Main>
            <Route exact path="/" component={LoadableRouteHome} />
            <Route path="/achieve" component={LoadableRouteGoals} />
            <Route path="/coach" component={LoadableRouteCoach} />
            <Route path="/resources" component={RouteResource} />
            <Route path="/profile" component={LodableRouteProfile} />
            <Route path="/settings" component={LoadableRouteSettings} />
            <Route path="/pulse" component={LoadablePulseDashboard} />
            <Route path="/pulsedemo" component={LoadablePulseDashboardDemo} />
          </Main>

          <MainMenu />
        </Body>
      </Wrap>
    )
  }
}

export default RouteAuthenticated
